import React from "react";
import Visibility from "./img/visibility.png";
import Visite from "./img/visite.png";
import Time from "./img/time.png";
import Credibility from "./img/credibility.png";
import Contact from "./img/contact.png";
import Concurrence from "./img/concurrence.png";
import "./index.css?vers=1.0.18";

const Utility = ({ bc }) => {
  return (
    <>
      <div className={bc && bc}>
        <section>
          <p className="title center">
            Quelles sont les avantages d'un site internet ?
          </p>
          <div className="soulignerTitre"></div>
          <p className="sousSousTitleSous texteGray center maxWidth500 padding20">
            Avec 60 millions de Français qui passent plus de 2h par jour sur
            Internet en moyenne, vos clients sont en ligne et y passent beaucoup
            de temps
          </p>
          <div className="grid3 centerMobile">
            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Visibility} />
              <p className="sousSousTitle padding20">
                Accroître votre visibilité
              </p>
              <p className="texte texteGray">
                Les visites sur votre site web vont accroître votre notoriété.
                Les utilisateurs aurons une visibilité directe sur les produits
                ou informations de votre site.
              </p>
            </div>
            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Credibility} />
              <p className="sousSousTitle padding20">
                Augmenter votre crédibilité
              </p>
              <p className="texte texteGray ">
                Votre site internet permet également à servir votre notoriété et
                votre image. En effet, avoir un site web soigné est gage d’une
                certaine crédibilité.
              </p>
            </div>

            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Concurrence} />
              <p className="sousSousTitle padding20">
                Démarquez vous de la Concurrence
              </p>
              <p className="texte texteGray">
                Profitez-en pour concevoir un site à votre image et expliquer en
                quoi votre entreprise est différente des autres. Si vous ne
                possédez pas de site, vous laissez le champ libre à vos
                concurrents.
              </p>
            </div>
            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Visite} />
              <p className="sousSousTitle padding20">
                Carte de visite en ligne
              </p>
              <p className="texte texteGray">
                Votre site internet est votre carte de visite en ligne.
                Disponible 24h/24 et 7j/7, il agit comme un point de contact
                pour vos clients potentiels.
              </p>
            </div>
            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Time} />
              <p className="sousSousTitle padding20"> Gagner du temps </p>
              <p className="texte texteGray">
                Une Foire aux Questions (FAQ) peut soulager votre
                service-clients et vous faire gagner un temps précieux.
              </p>
            </div>
            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Contact} />
              <p className="sousSousTitle padding20">Prise de contact</p>
              <p className="texte texteGray">
                Grâce au formulaire présent sur votre site, les
                clients/utilisateurs peuvent vous contactez facilement.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Utility;
