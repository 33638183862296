import React, { useState } from "react";
import Btn from "../../assets/btn";
import Remonter from "../../assets/remonter";
import { Link } from "react-router-dom";
import Logo from "./img/logo.svg";
import LogoMobile from "./img/logoMobile.png";
import "./index.css?vers=1.0.18";

const MenuAccueil = ({ page }) => {
  const [status, setStatus] = useState("none");

  let accueil = "";
  if (page === "accueil") {
    accueil = "accueil";
  }

  return (
    <>
      <Remonter />
      <div id="top"></div>
      <div className="menu_desktop">
        <div className="opacityForMenu"></div>
        <div className="menu_content">
          <div className="flex oppose paddingTopMenu">
            <Link to="/">
              <div className="flex alignItemCenter hover">
                <img className="maxWidth150" src={Logo} alt="digitac" />
                <h1 className="colorLogo originalFont">Digitac</h1>
              </div>
            </Link>
            <ul className="flex row alignItemCenter ulGapMenu">
              <Link className="hover" to="/">
                <li className={accueil + " overMenu texte"}>Accueil</li>
              </Link>
              <Link className="hover" to="/a-propos">
                <li className="overMenu texte">À-propos</li>
              </Link>
              <Link className="hover" to="/contact">
                <li className="overMenu texte">Contact</li>
              </Link>
              <Btn name="Devis" destination={"./devis"} />
            </ul>
          </div>
          <div className="width45 contentInMenu">
            <p className="sousSousTitle LMarginBottom">
              L'agence qui répond à vos besoins
            </p>
            <p className="title marginBottom">
              Développez votre activité <br />
              grâce au digital
            </p>
            <Btn name="En savoir plus" destination={"/a-propos"} />
          </div>
        </div>
      </div>

      {/* ---------------------------------------------------------------------------------------------------------- */}

      <div className="menu_mobile">
        <div className="opacityForMenu"></div>
        <div className="oppose flex column white paddMenu bc-imgMobile">
          <div className="flex alignItemCenter oppose relative">
            <Link to="/">
              <img className="imgLogo radius50" src={Logo} alt="digitac" />
            </Link>
            <h1 className="title white originalFont">Digitac</h1>
            <div className="imgLogo right borderBox iconMenu bc-pink">
              <i
                className="fas fa-bars"
                onClick={() =>
                  status === "ferme" || status === "none"
                    ? setStatus("ouvert")
                    : setStatus("ferme")
                }
              ></i>
            </div>
          </div>
          <div className="center sousTitle relative slogan">Accueil</div>
        </div>
        <div
          className={status === "ouvert" ? "clickFerme" : ""}
          onClick={() => setStatus("ferme")}
        ></div>
        <ul className={"paddMenu " + status}>
          <div className="croix" onClick={() => setStatus("ferme")}>
            <i className="fa fa-window-close" aria-hidden="true"></i>
          </div>
          <div className="title white Margin_bottom100 center originalFont">
            <img
              className="imgLogoMobileFuse radius50"
              src={LogoMobile}
              alt="digitac"
            />
            Digitac
          </div>
          <li
            className="itemMenu sousSousTitle white accueil"
            onClick={() =>
              status === "ferme" || status === "none"
                ? setStatus("ouvert")
                : setStatus("ferme")
            }
          >
            <Link to="/">Accueil</Link>
          </li>
          <hr className="separator" />
          <li
            className="itemMenu sousSousTitle white"
            onClick={() =>
              status === "ferme" || status === "none"
                ? setStatus("ouvert")
                : setStatus("ferme")
            }
          >
            <Link to="/a-propos">A-propos</Link>
          </li>
          <hr className="separator" />
          <li
            className="itemMenu sousSousTitle white"
            onClick={() =>
              status === "ferme" || status === "none"
                ? setStatus("ouvert")
                : setStatus("ferme")
            }
          >
            <Link to="/contact">Contact</Link>
          </li>
          <hr className="separator" />
          <li
            className="itemMenu sousTitle white"
            onClick={() =>
              status === "ferme" || status === "none"
                ? setStatus("ouvert")
                : setStatus("ferme")
            }
          >
            <Btn name="Devis en ligne gratuit" destination="/devis" />
          </li>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="sousSousTitleSous white flex column">
            <div className="flex LMarginBottom">
              <i className="material-icons iconLocation selfCenter">mail</i>
              &nbsp;
              <a href="mailto:contact@adrien-meynard.fr">
                contact@adrien-meynard.fr
              </a>
            </div>
            <div className="flex">
              <i className="material-icons iconLocation selfCenter">phone</i>
              &nbsp;
              <a href="tel:+33695632515">+33 6 95 63 25 15</a>
            </div>
          </div>
          <br />
          <br />
          <br />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="cadetblue "
              fillOpacity="1"
              d="M0,160L80,144C160,128,320,96,480,117.3C640,139,800,213,960,229.3C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg>
          <br />
          <br />
          <br />
          <div className="sousSousTitle cadetblue">
            L'agence qui répond à vos besoins
          </div>
          <br />
          <br />
          <br />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="cadetblue "
              fillOpacity="1"
              d="M0,160L80,144C160,128,320,96,480,117.3C640,139,800,213,960,229.3C1120,245,1280,203,1360,181.3L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
            ></path>
          </svg>
          <div className="marginForMobile"></div>
        </ul>
      </div>
    </>
  );
};

export default MenuAccueil;
