import React from "react";
import Btn from "../btn";
import "./index.css?vers=1.0.18";

const StartProject = ({ bc }) => {
  return (
    <>
      <div className={bc && bc}>
        <div className="startProjet bc-gradient centerMobile ">
          <div className="maxWidth1000 flex columnMobile alignItemCenter oppose padding30 auto boxSadowww radius5">
            <div className="flex alignItemCenter columnMobile width70">
              <div className="widthSvg">
                <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="#4175fc"
                    d="M1593 349L953 1629q-17 35-57 35-5 0-15-2-22-5-35.5-22.5T832 1600v-576H256q-22 0-39.5-13.5T194 975t4-42 29-30l1280-640q13-7 29-7 27 0 45 19 15 14 18.5 34.5T1593 349z"
                  ></path>
                </svg>
              </div>

              <p className="titleSousS blueColor borderBox LMarginBottoMmOBILEoNLY">
                Vous souhaitez démarrer un projet avec nous ?
              </p>
              {/* <p className="texte LMarginBottoMmOBILEoNLY">
              Etiam erat lectus, finibus eget commodo quis, tincidunt eget leo.
              Nullam quis vulputate orci, ac accumsan quam. Morbi fringilla
              congue libero.
            </p> */}
            </div>
            <div>
              <Btn name="Devis en ligne" destination="./devis" hover="yes" />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default StartProject;
