import React from "react";
import { Link } from "react-router-dom";
import Btn from "../btn";
import Facebook from "./img/facebook.svg";
import Insta from "./img/instagram.svg";
import Twitter from "./img/twitter.svg";
import "./index.css?vers=1.0.18";

const Footer = () => {
  return (
    <div className="footer bc-footer">
      <div className="footer1 white">
        <div className="grid4 grid4Footer">
          <div>
            <div className="sousTitle LMarginBottomFooter">Digitac</div>
            <p className="texte LMarginBottom">
              L'agence qui répond à vos besoins. N'hésitez pas à nous contacter
              !
            </p>
            <div className="flex gap20 gap20Footer">
              <img
                className="SvgReseaux hover"
                src={Facebook}
                alt="digitac"
                // onClick={() => {
                //   window.open("");
                // }}
              />
              <img
                className="SvgReseaux hover"
                src={Insta}
                alt="digitac"
                // onClick={() => {
                //   window.open("");
                // }}
              />
              <img
                className="SvgReseaux hover"
                src={Twitter}
                alt="digitac"
                // onClick={() => {
                //   window.open("");
                // }}
              />
            </div>
          </div>
          <div>
            <div className="sousTitle LMarginBottomFooter">Contact</div>
            <p className="texte">
              Montpellier &#x2022; 34 000 &#x2022; France &#x2022; Disponible
              dans toute la France.
            </p>
            <br />
            <p className="texte flex alignItemCenter">
              <i className="material-icons iconLocation">phone</i>
              &nbsp; <a href="tel:+33695632515">+33 6 95 63 25 15</a>
            </p>
            <br />

            <p className="texte flex alignItemCenter">
              <i className="material-icons iconLocation">mail</i>&nbsp;
              <a href="mailto:contact@adrien-meynard.fr">
                contact@adrien-meynard.fr
              </a>
            </p>
          </div>
          <div>
            <div className="sousTitle LMarginBottomFooter">Pages</div>
            <div className="flex column texte gap20 gap20Footer">
              <div>
                <Link className="hover" to="/">
                  Accueil
                </Link>
              </div>
              <div>
                <Link className="hover" to="/a-propos">
                  À propos
                </Link>
              </div>
              <div>
                <Link className="hover" to="/contact">
                  Contact
                </Link>
              </div>
              <div>
                <Btn name="Devis en ligne" destination="./devis" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="width100 hrFooter" />
      <div className="footer2 texte white">
        Copyright © 2022 Digitac | Propulsé par Digitac.fr
      </div>
    </div>
  );
};

export default Footer;
