import React from "react";
import "./index.css?vers=1.0.18";

const btnSubmit = ({ name }) => {
  return (
    <>
      <div className={"btnSubmit hover borderBox"}>{name}</div>
    </>
  );
};

export default btnSubmit;
