import React, { useState } from "react";
import Btn from "../../assets/btn";
import Remonter from "../../assets/remonter";
import { Link } from "react-router-dom";
import LogoMobile from "./img/logoMobile.png";
import PropTypes from "prop-types";
import Logo from "./img/logo.svg";
import "./index.css?vers=1.0.18";

const Menu = ({
  blockIntoMenu,
  title,
  sousTitle,
  description,
  topMarginBlockMenu,
  page,
  color,
}) => {
  const [status, setStatus] = useState("none");

  let a_propos = "";
  let contact = "";
  let colorBtn = "";

  if (page === "a_propos") {
    a_propos = "a_propos";
  }

  if (page === "contact") {
    contact = "contact";
  }

  if (color === "true") {
    colorBtn = "yes";
  }

  return (
    <>
      <Remonter />
      <div className="menu_desktopOther relative">
        <div className="opacityForMenuOther"></div>
        <div className="menu_content">
          <div className="flex oppose paddingTopMenu">
            <Link to="/">
              <div className="flex alignItemCenter hover">
                <img className="maxWidth150" src={Logo} alt="digitac" />
                <h1 className="colorLogo originalFont">Digitac</h1>
              </div>
            </Link>
            <ul className="flex row alignItemCenter ulGapMenu">
              <Link className="hover" to="/">
                <li className={"overMenu texte"}>Accueil</li>
              </Link>
              <Link className="hover" to="/a-propos">
                <li className={a_propos + " overMenu texte"}>À-propos</li>
              </Link>
              <Link className="hover" to="/contact">
                <li className={contact + " overMenu texte"}>Contact</li>
              </Link>
              <Btn name="Devis" destination={"./devis"} color={colorBtn} />
            </ul>
          </div>
        </div>
        <div
          className={
            topMarginBlockMenu
              ? "centerAbsolute center white topMarginBlockMenu opacity0100"
              : "centerAbsolute center white opacity0100"
          }
        >
          <p className="title LMarginBottom">{title}</p>
          <p className="sousSousTitle LMarginBottom">{sousTitle}</p>
          <p className="texte">{description}</p>
        </div>
      </div>
      <div className="noneMobile">
        {blockIntoMenu && (
          <div className="flex maxWidth1200 auto depasse">
            <div className="blockIntoAboutUs padd35Marg15 maxwidth33 bc-white borderBottom5Bleu padd030mobile">
              <div className="titleIntoAboutUs"></div>
              <div className="titleSous black LMarginBottom">
                Qui sommes nous ?
              </div>
              <div className="texte black">
                Digitac est une agence web spécialisé dans la création de site
                internet vitrine pour restaurateur ou pour toutes professions
                libérales (avocats ...). Nous travaillons dans toute la France
              </div>
            </div>
            <div className="blockIntoAboutUs padd35Marg15 maxwidth33 bc-white borderBottom5Bleu">
              <div className="titleIntoAboutUs"></div>
              <div className="titleSous black LMarginBottom">Notre Mission</div>
              <div className="texte black">
                Notre mission est d'accompagné tous nos clients à digitaliser
                leur entreprise dans divers buts :
                <br />
                <br />
                <div className="texte black flex alignItemCenter">
                  <i className="material-icons chevronStyleFaisons">
                    arrow_drop_up
                  </i>
                  Accroître leur visibilité
                </div>
                <div className="texte black flex alignItemCenter">
                  <i className="material-icons chevronStyleFaisons">
                    arrow_drop_up
                  </i>
                  Augmenter leur crédibilité
                </div>
                <div className="texte black flex alignItemCenter">
                  <i className="material-icons chevronStyleFaisons">
                    arrow_drop_up
                  </i>
                  Se démarquer
                </div>
                <div className="texte black flex alignItemCenter">
                  <i className="material-icons chevronStyleFaisons">
                    arrow_drop_up
                  </i>
                  Gagner du temps
                </div>
              </div>
            </div>
            <div className="blockIntoAboutUs padd35Marg15 maxwidth33 bc-white borderBottom5Bleu">
              <div className="titleIntoAboutUs"></div>
              <div className="titleSous black LMarginBottom">Nos services</div>
              <div className="texte black flex alignItemCenter LMarginBottom">
                <i className="material-icons chevronStyleFaisons">
                  arrow_drop_up
                </i>
                Création site internet
              </div>
              <div className="texte black flex alignItemCenter LMarginBottom">
                <i className="material-icons chevronStyleFaisons">
                  arrow_drop_up
                </i>
                Design unique
              </div>
              <div className="texte black flex alignItemCenter LMarginBottom">
                <i className="material-icons chevronStyleFaisons">
                  arrow_drop_up
                </i>
                Hébergement + Nom de domaine
              </div>
              <div className="texte black flex alignItemCenter LMarginBottom">
                <i className="material-icons chevronStyleFaisons">
                  arrow_drop_up
                </i>
                Optimisation SEO
              </div>
              <div className="texte black flex alignItemCenter">
                <i className="material-icons chevronStyleFaisons">
                  arrow_drop_up
                </i>
                Support technique
              </div>
            </div>
          </div>
        )}
      </div>

      {/* ---------------------------------------------------------------------------------------------------------- */}

      <div className="menu_mobile">
        <div className="opacityForMenu"></div>
        <div className="oppose flex column white paddMenu bc-imgMobile">
          <div className="flex alignItemCenter oppose relative">
            <Link to="/">
              <img className="imgLogo radius50" src={Logo} alt="digitac" />
            </Link>
            <h1 className="title white originalFont">Digitac</h1>
            <div className="imgLogo right borderBox iconMenu bc-pink">
              <i
                className="fas fa-bars"
                onClick={() =>
                  status === "ferme" || status === "none"
                    ? setStatus("ouvert")
                    : setStatus("ferme")
                }
              ></i>
            </div>
          </div>
          <div className="center sousTitle relative slogan">{title}</div>
        </div>
        <div
          className={status === "ouvert" ? "clickFerme" : ""}
          onClick={() => setStatus("ferme")}
        ></div>
        <ul className={"paddMenu " + status}>
          <div className="croix" onClick={() => setStatus("ferme")}>
            <i className="fa fa-window-close" aria-hidden="true"></i>
          </div>
          <div className="title white Margin_bottom100 center originalFont">
            <img
              className="imgLogoMobileFuse radius50"
              src={LogoMobile}
              alt="digitac"
            />
            Digitac
          </div>
          <li
            className="itemMenu sousSousTitle white"
            onClick={() =>
              status === "ferme" || status === "none"
                ? setStatus("ouvert")
                : setStatus("ferme")
            }
          >
            <Link to="/">Accueil</Link>
          </li>
          <hr className="separator" />
          <li
            className={"itemMenu sousSousTitle white " + a_propos}
            onClick={() =>
              status === "ferme" || status === "none"
                ? setStatus("ouvert")
                : setStatus("ferme")
            }
          >
            <Link to="/a-propos">A-propos</Link>
          </li>
          <hr className="separator" />
          <li
            className={"itemMenu sousSousTitle white " + contact}
            onClick={() =>
              status === "ferme" || status === "none"
                ? setStatus("ouvert")
                : setStatus("ferme")
            }
          >
            <Link to="/contact">Contact</Link>
          </li>
          <hr className="separator" />
          <li
            className="itemMenu sousTitle white"
            onClick={() =>
              status === "ferme" || status === "none"
                ? setStatus("ouvert")
                : setStatus("ferme")
            }
          >
            <Btn name="Devis en ligne gratuit" destination="/devis" />
          </li>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="sousSousTitleSous white flex column">
            <div className="flex LMarginBottom">
              <i className="material-icons iconLocation selfCenter">mail</i>
              &nbsp;
              <a href="mailto:contact@adrien-meynard.fr">
                contact@adrien-meynard.fr
              </a>
            </div>
            <div className="flex">
              <i className="material-icons iconLocation selfCenter">phone</i>
              &nbsp;
              <a href="tel:+33695632515">+33 6 95 63 25 15</a>
            </div>
          </div>
          <br />
          <br />
          <br />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="cadetblue "
              fillOpacity="1"
              d="M0,160L80,144C160,128,320,96,480,117.3C640,139,800,213,960,229.3C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg>
          <br />
          <br />
          <br />
          <div className="sousSousTitle cadetblue">
            L'agence qui répond à vos besoins
          </div>
          <br />
          <br />
          <br />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="cadetblue "
              fillOpacity="1"
              d="M0,160L80,144C160,128,320,96,480,117.3C640,139,800,213,960,229.3C1120,245,1280,203,1360,181.3L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
            ></path>
          </svg>
          <div className="marginForMobile"></div>
        </ul>
      </div>
      <div className="desktopNone">
        {blockIntoMenu && (
          <section>
            <div className="flex column maxWidth1200 auto gap70">
              <div className="blockIntoAboutUs intoGridMargin bc-white padd030mobile">
                <div className="titleIntoAboutUs"></div>
                <div className="titleSous black LMarginBottom">
                  Qui sommes nous ?
                </div>
                <div className="texte black">
                  Digitac est une agence web spécialisé dans la création de site
                  internet vitrine pour restaurateur ou pour toutes professions
                  libérales (avocats ...). Nous travaillons dans toute la France
                </div>
              </div>
              <div className="blockIntoAboutUs intoGridMargin  bc-white">
                <div className="titleIntoAboutUs"></div>
                <div className="titleSous black LMarginBottom">
                  Notre Mission
                </div>
                <div className="texte black">
                  Notre mission est d'accompagné tous nos clients à digitaliser
                  leur entreprise dans divers buts :
                  <br />
                  <br />
                  <div className="texte black flex alignItemCenter">
                    <i className="material-icons chevronStyleFaisons">
                      arrow_drop_up
                    </i>
                    Accroître leur visibilité
                  </div>
                  <div className="texte black flex alignItemCenter">
                    <i className="material-icons chevronStyleFaisons">
                      arrow_drop_up
                    </i>
                    Augmenter leur crédibilité
                  </div>
                </div>
                <div className="texte black flex alignItemCenter">
                  <i className="material-icons chevronStyleFaisons">
                    arrow_drop_up
                  </i>
                  Se démarquer
                </div>
                <div className="texte black flex alignItemCenter">
                  <i className="material-icons chevronStyleFaisons">
                    arrow_drop_up
                  </i>
                  Gagner du temps
                </div>
              </div>
              <div className="blockIntoAboutUs intoGridMargin  bc-white">
                <div className="titleIntoAboutUs"></div>
                <div className="titleSous black LMarginBottom">
                  Nos services
                </div>
                <div className="texte black flex alignItemCenter">
                  <i className="material-icons chevronStyleFaisons">
                    arrow_drop_up
                  </i>
                  Création site internet
                </div>
                <div className="texte black flex alignItemCenter">
                  <i className="material-icons chevronStyleFaisons">
                    arrow_drop_up
                  </i>
                  Design unique
                </div>
                <div className="texte black flex alignItemCenter">
                  <i className="material-icons chevronStyleFaisons">
                    arrow_drop_up
                  </i>
                  Hébergement + Nom de domaine
                </div>
                <div className="texte black flex alignItemCenter">
                  <i className="material-icons chevronStyleFaisons">
                    arrow_drop_up
                  </i>
                  Optimisation SEO
                </div>
                <div className="texte black flex alignItemCenter">
                  <i className="material-icons chevronStyleFaisons">
                    arrow_drop_up
                  </i>
                  Support technique
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

Menu.propTypes = {
  title: PropTypes.string,
  sousTitle: PropTypes.string,
  description: PropTypes.any,
  blockIntoMenu: PropTypes.bool,
  topMarginBlockMenu: PropTypes.bool,
};

Menu.defaultProps = {
  blockIntoMenu: false,
  topMarginBlockMenu: false,
};

export default Menu;
