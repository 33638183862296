import React, { useState } from "react";
import dataJson from "./data/data.json";
import ChooseUs from "./img/chooseUs.png";
import Search from "./search";
import "./index.css?vers=1.0.18";

const Page = () => {
  const data = dataJson.data;

  return (
    <>
      <section className="noPaddingNoMarginX">
        <div className="flex flexReverse">
          <div className="width50">
            <img className="max-Width100" src={ChooseUs} at="" />
          </div>
          <Search data={data} />
        </div>
      </section>
    </>
  );
};

export default Page;
