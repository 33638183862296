import React, { useState } from "react";
import Loading from "../assets/loading/loading";
import { ToastContainer, toast } from "react-toastify";
import imgContact from "./img/contact.png";
import axios from "axios";
import Menu from "../assets/menu";
import Btn from "../assets/btn";
import BtnSubmit from "../assets/btnSubmit";
import StartProject from "../assets/startProject";
import "react-toastify/dist/ReactToastify.css";
import "./index.css?vers=1.0.18";

const Contact = () => {
  document.title = "Digitac | Contactez-nous";

  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const [resultForm, setResultForm] = useState("");

  const [loading, setLoading] = useState("false");

  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-])/;

  const urlApiSendMail = "https://digitac.adrien-meynard.fr/send/index.php";

  const Submit = (e) => {
    e.preventDefault();
    setLoading("true");
    setNameErr("");
    setEmailErr("");
    setResultForm("");

    let err = 0;
    if (name === "") {
      setNameErr("Champ vide");
      err = 1;
    }

    if (email === "") {
      setEmailErr("Champ vide");
      err = 1;
    } else if (!email.match(regex)) {
      setEmailErr("Email non valide");
      err = 1;
    }

    if (err === 1) {
      setLoading("false");
      toast.error("Erreur ..");
    }

    if (err === 0) {
      axios({
        method: "POST",
        url: urlApiSendMail,
        data: {
          contact: 1,
          email: email,
          message: name,
          token: "rtugifdnk6",
        },
      })
        .then((res) => {
          setNameErr("");
          setEmailErr("");
          setName("");
          setEmail("");
          toast.success("Email envoyé !");
          setLoading("false");
          setResultForm("Une copie vous a été envoyé. Vérifiez vos spams");
        })
        .catch((err) => {
          toast.error("Erreur ! Veuillez réessayer dans quelques minutes");
          setLoading("false");
        });
    }
  };
  return (
    <>
      <Menu
        title="Contactez-nous"
        sousTitle="Bienvenue chez Digitac"
        description={
          <Btn name="Devis en ligne gratuit" destination="./devis" />
        }
        topMarginBlockMenu={true}
        page="contact"
      />
      <Loading status={loading} />
      <div className="marginDiv">
        <div className="marginCoteContact flex alignItemCenter">
          <div className="contactGauche">
            <p className="sousTitle litlePaddingCOntact">Email</p>
            <input
              type="text"
              placeholder="votre email ..."
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <p className="err texte marginBottomPlus">{emailErr}</p>
            <br />
            <p className="sousTitle litlePaddingCOntact">Message</p>
            <textarea
              placeholder="votre message ..."
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <p className="err texte marginBottomPlus">{nameErr}</p>
            <br />
            <br />
            <div onClick={(e) => Submit(e)}>
              <BtnSubmit name="Soumettre le formulaire" />
            </div>
            <p className="success texte marginBottomPlus">{resultForm}</p>
          </div>
          <div className="contactDroite">
            <img src={imgContact} />
          </div>
        </div>
      </div>
      <br />
      <ToastContainer />
      <StartProject />
    </>
  );
};

export default Contact;
