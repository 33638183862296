import React from "react";
import Menu from "../assets/menu";
import Btn from "../assets/btn";
import BearGif from "./img/bear.gif";

import "./index.css?vers=1.0.18";

const Page404 = () => {
  document.title = "Digitac | Erreur 404";
  return (
    <>
      <Menu
        title="La page demandée n'a pas pu être trouvée."
        sousTitle="Erreur 404"
      />
      <div className="bc-beige">
        <section>
          <div className="center marginBottom">
            <Btn
              name="Retour à la page d'accueil"
              destination="/"
              hover={true}
            />
          </div>
          <div className="center">
            <img src={BearGif} alt="digitac" />
          </div>
        </section>
      </div>
    </>
  );
};

export default Page404;
