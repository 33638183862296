import React from "react";
import Design from "./img/design.svg";
import Dev from "./img/dev.svg";
import Seo from "./img/seo.svg";
import Server from "./img/server.svg";
import Bdd from "./img/bdd.svg";
import Help from "./img/help.svg";
import BtnSubmit from "../assets/btnSubmit";
import "./index.css?vers=1.0.18";

const Aide = () => {
  return (
    <>
      <div className="bc-beige">
        <section>
          <p className="title center padd030mobile">Nos secteurs d'activités</p>
          <div className="soulignerTitre"></div>
          <p className="sousSousTitleSous texteGray center maxWidth500 padding20 padd030mobile">
            Digitac est spécialisé dans la création de site internet (A à Z),
            cependant nous proposons divers offres complémentaires.
          </p>
          <div className="grid3 centerMobile">
            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Dev} />
              <p className="sousSousTitle padding20">Développement</p>
              <p className="texte texteGray">
                Création de site internet / application en tout genre
                (e-commerce, vitrine, click and collect ...)
              </p>
            </div>
            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Design} />
              <p className="sousSousTitle padding20">Design</p>
              <p className="texte texteGray ">
                Création d'une identité visuelle afin d'améliorer l'expérience
                d'un produit ou d'un service
              </p>
            </div>

            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Seo} />
              <p className="sousSousTitle padding20">Référencement & SEO</p>
              <p className="texte texteGray">
                Positionnement et maintien de votre site internet en tête des
                moteurs de recherche
              </p>
            </div>
            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Server} />
              <p className="sousSousTitle padding20">
                Serveur & Nom de domaine
              </p>
              <p className="texte texteGray">
                Hébergement de votre site internet / application sur nos
                différents serveurs. Achat de votre nom de domaine. Sauvegarde
                régulière
              </p>
            </div>
            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Bdd} />
              <p className="sousSousTitle padding20"> Base de Données & SQL </p>
              <p className="texte texteGray">
                Gérer ou créer votre base de données afin de la rendre plus
                performante. Requête(s) SQL optimisée(s) afin de récupérer
                l'information qui correspond
              </p>
            </div>
            <div className="intoGrid bc-white">
              <img className="imgAideSvg" src={Help} />
              <p className="sousSousTitle padding20"> Help & Support </p>
              <p className="texte texteGray">
                Support réactif pour tout problème sur un site existant ou si
                vous souhaitez avoir des informations supplémentaires concernant
                nos offres
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Aide;
