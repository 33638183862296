import React from "react";
import imgLoading from "./img/loading.svg";
import "./index.css?vers=1.0.18";

const Loading = ({ status }) => {
  return (
    <div className={status + " load"}>
      <img className="imgLoader" src={imgLoading} />
    </div>
  );
};

export default Loading;
