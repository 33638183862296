import React from "react";
import Accordion from "../../assets/accordion";

const Search = ({ data }) => {
  return (
    <>
      <div className="padding80 width50 padding050">
        <p className="title left LMarginBottom">Pourquoi Nous Choisir ?</p>
        <p className="sousSousTitleSous texteGray">
          Digitac vous accompagne de A à Z dans tous vos projets digitaux. Nous
          vous proposons une dizaine de service avec le meilleur rapport qualité
          prix possible.
        </p>
        <br />
        <div className="bc-white marginMobileAA LMarginBottom">
          {data.map((item, key) => {
            return (
              <Accordion
                key={key}
                title={item.title}
                description={item.description}
                bool={key % 2 !== 0 ? true : false}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Search;
