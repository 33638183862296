import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./index.css?vers=1.0.18";

const BtnDevis = ({ name }) => {
  return (
    <>
      <div className={"btnDevis"}>{name}</div>
    </>
  );
};

BtnDevis.propTypes = {};

BtnDevis.defaultProps = {};

export default BtnDevis;
