import React from "react";
import Menu from "../assets/menu";
import StartProject from "../assets/startProject";
import Number from "../number";
import ChooseUs from "../chooseUs";
import Utility from "../utility";
import Custumers from "../customers";
import "./index.css?vers=1.0.18";

const APropos = () => {
  document.title = "Digitac | À-propos";
  return (
    <>
      <div className="bc-beige">
        <Menu
          title="À-propos"
          sousTitle="Bienvenue chez Digitac"
          description="L'agence web qui propose le meilleur rapport qualité / prix"
          blockIntoMenu={true}
          page="a_propos"
        />
        <ChooseUs />
        <Utility bc="bc-white" />
        <Number />
        <Custumers />
        <StartProject />
      </div>
    </>
  );
};

export default APropos;
