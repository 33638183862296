import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Accueil from "./components/accueil";
import APropos from "./components/a-propos";
import Contact from "./components/contact";
import Devis from "./components/devis";
import Page404 from "./components/page404";
import Footer from "./components/assets/footer";

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return "";
};

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <div>
          <Switch>
            <Route exact path="/" component={Accueil} />
            <Route path="/a-propos" component={APropos} />
            <Route path="/contact" component={Contact} />
            <Route path="/devis" component={Devis} />
            <Route path="/" component={Page404} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </>
  );
};

export default App;
