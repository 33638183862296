import React from "react";
// import testImg from "./img/testImg.png";
import "./index.css?vers=1.0.18";

const Work = () => {
  return (
    <>
      <section>
        <p className="title center padd030mobile">Comment travaillons-nous ?</p>
        <div className="soulignerTitre"></div>
        <div className="grid2 centerMobile marginBottom">
          <div className="inGrid2">
            <div className="flex padding20 alignItemCenter columnMobile">
              <div className="padding-Right25">
                {/* <img className="imgAideSvg" src={testImg} alt="digitac" /> */}

                <i className="far fa-lightbulb stepFont"></i>
              </div>
              <div>
                <p className="sousSousTitle padding20"> Étape 1 </p>
                <p className="texte">
                  Lors d'un appel téléphonique ou par mail vous nous expliquer
                  les tenants et les aboutissants de votre projet afin de cerner
                  au mieux vos besoins
                </p>
              </div>
            </div>
          </div>
          <div className="inGrid2">
            <div className="flex padding20 alignItemCenter columnMobile">
              <div className="padding-Right25">
                <i className="far fa-paper-plane stepFont"></i>
              </div>
              <div>
                <p className="sousSousTitle padding20"> Étape 2 </p>
                <p className="texte">
                  Suite à votre demande, nous vous envoyons un devis dans les
                  meilleurs délais pour le service de votre choix
                </p>
              </div>
            </div>
          </div>
          <div className="inGrid2">
            <div className="flex padding20 alignItemCenter columnMobile">
              <div className="padding-Right25">
                <i className="far fa-map stepFont"></i>
              </div>
              <div>
                <p className="sousSousTitle padding20"> Étape 3 </p>
                <p className="texte">
                  Si le devis vous convient, nous commençons votre projet. Vous
                  serez informé de l'avancement au fil des jours. Pour la
                  création d'un site internet nous vous offrons 3 modifications*
                  dans l'année.
                </p>
              </div>
            </div>
          </div>
          <div className="inGrid2">
            <div className="flex padding20 alignItemCenter columnMobile">
              <div className="padding-Right25">
                <i className="far fa-lemon stepFont"></i>
              </div>
              <div>
                <p className="sousSousTitle padding20 "> Étape 4 </p>
                <p className="texte">
                  Une fois votre projet livré, pour un site internet, nous nous
                  occupons de la maintenance* ainsi que des potentiels
                  modifications (design, contenu ..)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Work;
