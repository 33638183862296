import React, { useState } from "react";
import Loading from "../assets/loading/loading";
import { ToastContainer, toast } from "react-toastify";
import DevisImg from "./img/devis.png";
import axios from "axios";
import Menu from "../assets/menu";
import BtnDevis from "../assets/btnDevis";
import BtnSubmit from "../assets/btnSubmit";
import "./devis.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css?vers=1.0.18";

const data = [
  {
    question: "De quel service avez-vous besoin ?",
    choix: [
      "Site Vitrine",
      "Application Mobile",
      "Blog",
      "e-commerce",
      "Autre",
    ],
  },
  {
    question: "Quelles sont vos attentes concernant le design du site ?",
    choix: [
      "Un design standard, simple et efficace",
      "Un design soigné réalisé à partir de vos propres maquettes",
      "Un design haut de gamme entièrement sur mesure",
    ],
  },
  {
    question: "Avez-vous besoin d'un logo ?",
    choix: ["Oui", "Non"],
  },
  {
    question: "Votre site doit-il être disponible en plusieurs langues ?",
    choix: ["Oui", "Non"],
  },
  {
    question: "Nombre de Page(s) du site",
    choix: ["1-2", "3-4", "+5", "À déterminer"],
  },
  {
    question: "Nombre de Produit(s) à rentrer dans le site",
    choix: ["1-20", "21-40", "41-60", "+60", "Aucun", "À déterminer"],
  },
  {
    question: "Hébergement du site (pour 1 an)",
    choix: [
      "Je compte m'en occuper moi-même",
      "Je souhaite un hébergement standard",
      "Je souhaite un hébergement premium (pour un site à fort trafic)",
    ],
  },
  {
    question: "Maintenance du site et évolution(s) (pour 1an)",
    choix: [
      "Je compte m'en occuper moi-même",
      "Je souhaite un forfait de maintenance minimal",
      "Je souhaite un forfait avec maintenance et évolutions régulières",
    ],
  },
];

const Devis = () => {
  document.title = "Digitac | Devis en ligne gratuit";
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [devisErr, setDevisErr] = useState("");
  const [resultForm, setResultForm] = useState("");

  const [loading, setLoading] = useState("false");

  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-])/;

  const [questionNb, setQuestionNb] = useState(0);
  const [end, setEnd] = useState(false);
  const { question } = data[questionNb];
  const [arrayReponses] = useState([]);

  const questions = [];
  data.forEach((element) => {
    questions.push(element.question);
  });

  const AddItem = (item) => {
    if (questionNb < data.length - 1) {
      arrayReponses.push(item);
      setQuestionNb(questionNb + 1);
    } else if (questionNb === data.length - 1) {
      arrayReponses.push(item);
      setEnd(true);
    }
  };

  const RemoveItem = () => {
    if (!end) {
      setQuestionNb(questionNb - 1);
    }
    arrayReponses.pop();

    if (questionNb !== data.length) {
      setEnd(false);
    }
  };

  const PasseItem = () => {
    if (questionNb < data.length - 1) {
      arrayReponses.push("Non répondu");
      setQuestionNb(questionNb + 1);
    } else if (questionNb === data.length - 1) {
      arrayReponses.push("Non répondu");
      setEnd(true);
    }
  };

  const urlApiSendMail = "https://digitac.adrien-meynard.fr/send/index.php";

  const Submit = (e) => {
    e.preventDefault();
    setLoading("true");
    setEmailErr("");
    setDevisErr("");
    setResultForm("");

    let err = 0;

    if (email === "") {
      setEmailErr("Champ vide");
      err = 1;
    } else if (!email.match(regex)) {
      setEmailErr("Email non valide");
      err = 1;
    }

    if (!end) {
      setDevisErr("Devis incomplet");
      err = 1;
    }

    if (err === 1) {
      setLoading("false");
      toast.error("Erreur ..");
    }

    if (err === 0) {
      axios
        .post(urlApiSendMail, {
          devis: 1,
          email: email,
          responses: arrayReponses,
          questions: questions,
          token: "rtugifdnk6",
        })
        .then((res) => {
          setEmailErr("");
          setEmail("");
          setDevisErr("");
          setQuestionNb(0);
          arrayReponses.splice(0, arrayReponses.length);
          setEnd(false);
          toast.success("Devis envoyé !");
          setResultForm(
            "Un récapitulatif vous a été envoyé. Vérifiez vos spams"
          );
          setLoading("false");
        })
        .catch((err) => {
          toast.error("Erreur ! Veuillez réessayer dans quelques minutes");
          setLoading("false");
        });
    }
  };
  return (
    <>
      <Menu
        title="Devis en ligne gratuit"
        sousTitle="Bienvenue chez Digitac"
        description="Remplissez le formulaire ci-dessous. Nous vous enverrons le devis dans votre boite mail sous 48 heures"
        topMarginBlockMenu={true}
        page="devis"
        color="true"
      />
      <Loading status={loading} />
      <div className="marginDiv">
        <div className="marginCoteContact flex alignItemCenter">
          <div className="contactGauche">
            <p className="sousTitle litlePaddingCOntact">Email</p>
            <input
              type="text"
              placeholder="votre email ..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // onKeyPress={(e) => e.preventDefault()}
            />
            <p className="err texte marginBottomPlus">{emailErr}</p>
            <div className="width80 mobile100 petitPadding">
              {!end ? (
                <div>
                  <div className="flex LMarginBottom heightSix">
                    {data.map((item, key) =>
                      key > questionNb ? (
                        <div key={key} className="width95 pasEnCours"></div>
                      ) : (
                        <div key={key} className="width95 enCours"></div>
                      )
                    )}
                  </div>
                  <p className="questionDevis sousSousTitle nocapi">
                    {question}
                  </p>
                  <div>
                    <div className="grid3 devisGrid">
                      {questionNb <= data.length ? (
                        data[questionNb].choix.map((item, key) => (
                          <div
                            className={"intoGrid3with"}
                            key={key}
                            onClick={() => AddItem(item)}
                          >
                            <BtnDevis name={item} />
                          </div>
                        ))
                      ) : (
                        <div>Stop</div>
                      )}
                    </div>
                  </div>
                  <div className="flex oppose">
                    {questionNb >= 1 ? (
                      <div onClick={() => RemoveItem()}>
                        <span className="underline gray hover texte">
                          &lt; Précédent
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span className="underline gray hover"></span>
                      </div>
                    )}
                    {questionNb <= data.length && (
                      <div onClick={() => PasseItem()}>
                        <span className="underline gray hover texte">
                          Passer &gt;
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <br />
                  <p className="questionDevis sousSousTitle nocapi">
                    Récapitulatif
                  </p>
                  <div className="devisGrid flex column gap10">
                    {arrayReponses.map((item, key) => (
                      <p className="texte" key={key}>
                        <span className="">{questions[key]}</span> :{" "}
                        <span className="gray">{item}</span>
                        <br />
                      </p>
                    ))}
                  </div>
                  {questionNb >= 1 && (
                    <div className="" onClick={() => RemoveItem()}>
                      <span className="underline gray hover texte">
                        &lt; Précédent
                      </span>
                    </div>
                  )}
                </div>
              )}
              <p className="err texte marginBottomPlus">{devisErr}</p>
            </div>
            <div onClick={(e) => Submit(e)}>
              <BtnSubmit name="Soumettre le devis" />
            </div>
            <p className="success texte marginBottomPlus">{resultForm}</p>
          </div>
          <div className="contactDroite">
            <img src={DevisImg} />
          </div>
        </div>
      </div>
      <br />
      <ToastContainer />
    </>
  );
};

export default Devis;
