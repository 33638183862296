import React, { useState } from "react";
import "./index.css?vers=1.0.18";

const Accordion = ({ title, description, bool }) => {
  const [statusAccordion, setStatusAccordion] = useState("closed");
  const [chevron, setChevron] = useState("cvClosed");

  let BoolAccord = "";
  if (bool) {
    BoolAccord = "";
  }
  return (
    <>
      <div className={"accordion " + BoolAccord}>
        <div
          className="padding5-20 hover"
          onClick={() =>
            statusAccordion === "open" && description
              ? description &&
                (setStatusAccordion("closed"), setChevron("cvClosed"))
              : (setStatusAccordion("open"), setChevron("cvOpen"))
          }
        >
          <div className="flex black">
            <div className="flex oppose width95">
              <div className="flex column width80 justifyContentCenter">
                <p className="sousSousTitleSous paddingMobile">{title}</p>
              </div>
            </div>
            <div className={"right selfCenter chevron " + chevron}>
              <i className="material-icons chevronStyleFaisons2">
                arrow_drop_up
              </i>
            </div>
          </div>
        </div>
        <div className={"bc-gray-clair flex padding0 " + statusAccordion}>
          <p className="texte LMarginBottomL">{description}</p>
        </div>
      </div>
    </>
  );
};

export default Accordion;
