import React from "react";
import { Link } from "react-router-dom";
import "./index.css?vers=1.0.18";

const Btn = ({ name, destination, hover, color }) => {
  return (
    <>
      {destination ? (
        <Link to={destination}>
          <span
            className={
              color ? "btnClick hover " : hover ? "btn btnHover" : "btn"
            }
          >
            {name}
          </span>
        </Link>
      ) : (
        <span
          className={color ? "btnClick hover " : hover ? "btn btnHover" : "btn"}
        >
          {name}
        </span>
      )}
    </>
  );
};

export default Btn;
