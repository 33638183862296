import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const AugmentationNumber = ({ end, intervalVar, intervalVarDeux, texte }) => {
  const [intervalConst, setIntervalConst] = useState("Step1");
  const [number, setNumber] = useState(0);

  let i = 0;

  if (end > 400 && end < 600) {
    i = 400;
  }

  if (end < 151) {
    intervalVar = 10;
    intervalVarDeux = 100;
  }

  if (end < 100) {
    intervalVar = 20;
    intervalVarDeux = 120;
  }

  if (end < 50) {
    intervalVar = 80;
    intervalVarDeux = 200;
  }

  useEffect(() => {
    const intervalBoucle = setInterval(() => {
      if (i >= end - 10) return setIntervalConst("Step2");

      i++;
      setNumber(i);
    }, intervalVar);
    return () => clearInterval(intervalBoucle);
  }, []);

  useEffect(() => {
    if (intervalConst === "Step2") {
      i = number;
      const intervalBoucleA = setInterval(() => {
        if (i === end) return;
        i++;
        setNumber(i);
      }, intervalVarDeux);
      return () => clearInterval(intervalBoucleA);
    }
  }, [intervalConst]);

  return (
    <>
      <div className="title bleu marginRightClient">
        {number}
        {texte}
      </div>
    </>
  );
};

AugmentationNumber.propTypes = {
  intervalVarDeux: PropTypes.number,
  intervalVar: PropTypes.number,
};

AugmentationNumber.defaultProps = {
  intervalVarDeux: 80,
  intervalVar: 1,
};

export default AugmentationNumber;
