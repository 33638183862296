import React, { useRef } from "react";
import AugmentationNumber from "../assets/augmentationNumber";
import IsVisible from "react-is-visible";
import "./index.css?vers=1.0.18";

const Number = () => {
  return (
    <>
      <section>
        <div>
          <p className="titleSous center">Quelques chiffres ...</p>
          <div className="soulignerTitre"></div>
          <div className="flex oppose columnMobile padding20 noPaddingBottom gap50Mobile maxWidth1000 auto">
            <div className="flex alignItemCenter">
              <IsVisible once>
                {(isVisible) => (
                  <div>{isVisible && <AugmentationNumber end={87} />}</div>
                )}
              </IsVisible>

              <p className="texte bold">Clients Satisfaits</p>
            </div>

            <div className="flex alignItemCenter">
              <IsVisible once>
                {(isVisible) => (
                  <div>{isVisible && <AugmentationNumber end={150} />}</div>
                )}
              </IsVisible>
              <p className="texte bold">Projets Finis</p>
            </div>

            <div className="flex alignItemCenter">
              <IsVisible once>
                {(isVisible) => (
                  <div>{isVisible && <AugmentationNumber end={28} />}</div>
                )}
              </IsVisible>
              <p className="texte bold">Collaborateurs</p>
            </div>

            <div className="flex alignItemCenter">
              <IsVisible once>
                {(isVisible) => (
                  <div>
                    {isVisible && <AugmentationNumber end={590} texte="K+" />}
                  </div>
                )}
              </IsVisible>

              <p className="texte bold">Lines de Code</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Number;
