import React from "react";
import ImgTeteTemoignage from "./img/tete.jpg";
import "./index.css?vers=1.0.18";

const Custumers = () => {
  return (
    <>
      <section className="noTopSection nowidthMax padding80Mobile">
        <p className="titleSous center">"Rapide et Efficace"</p>
        <div className="soulignerTitre"></div>
        <p className="texte texteGray center maxWidth500 padding20">
          Nous avons fait appel à Digitac afin de donner un coup de jeune à
          notre site internet. Nous en avons profité pour y ajouter de nouvelles
          fonctionnalités. Je recommande cette agence.
        </p>
        <div className="LMarginBottom center">
          <img
            className="imgTemoignage"
            src={ImgTeteTemoignage}
            alt="digitac"
          />
        </div>
        <div className="center">
          <p className="sousSousTitle LMarginBottomLL">John Doga</p>
          <p className="texte">Entrepreneur</p>
        </div>
      </section>
    </>
  );
};

export default Custumers;
