import React from "react";
import MenuAccueil from "../assets/menuAccueil";
import Aide from "../aide";
import Work from "../work";
import Portfolio from "../portfolio";
import Utility from "../utility";
import StartProject from "../assets/startProject";
import Team from "../team";

import "./index.css?vers=1.0.18";

const Accueil = () => {
  document.title = "Digitac | L'agence qui répond à vos besoins";

  return (
    <>
      <MenuAccueil page="accueil" />
      <Aide />
      <Work />
      {/* <Portfolio /> */}
      <Utility bc="bc-beige" />
      {/* <Team /> */}
      <StartProject bc="bc-beige" />
    </>
  );
};

export default Accueil;
